import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import AppTheme from 'src/styles/AppTheme';
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';

import Routes from './routes';

const App = () => {
  return (
    <Router>
      <CookiesProvider>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          preventDuplicate
        >
          <AppTheme>
            <CssBaseline />
            <Routes />
          </AppTheme>
        </SnackbarProvider>
      </CookiesProvider>
    </Router>
  );
};

export default App;
