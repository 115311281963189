import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  CssBaseline,
  Divider,
  Link,
} from '@mui/material';

import loginBg from 'src/assets/signin/login-bg.jpg';

import useBreakpoints from 'src/hooks/useBreakpoints';

const stage = process.env.REACT_APP_STAGE;

const Layout = ({ children }) => {
  const [meyzerlogoWhite, setMeyzerlogoWhite] = useState();
  import(
    `src/assets/signin/meyzerlogo-white-${stage ? stage : 'prod'}.png`
  ).then((logo) => {
    setMeyzerlogoWhite(logo.default);
  });

  const { smBelow } = useBreakpoints();

  return (
    <main>
      <Box
        display="flex"
        flexWrap="flex-nowrap"
        height="100vh"
        width="100%"
        style={{
          backgroundColor: '#fff',
        }}
      >
        <CssBaseline />
        <Box display={smBelow ? 'none' : 'block'} flexGrow={1} width="18%">
          <Container
            style={{
              paddingTop: 30,
              height: '100%',
              backgroundColor: '#000',
              backgroundImage: `url(${loginBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              backgroundSize: 'auto 70%',
            }}
          >
            <Box width="35%">
              <img src={meyzerlogoWhite} style={{ width: '100%' }} />
            </Box>
            <Box mt={6}>
              <Typography
                // variant="h1"
                align="left"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#FFFFFF',
                  fontSize: '40px',
                  fontWeight: 700,
                  lineHeight: '48px',
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <span>Grow Your Digital</span> <span>Business Journey</span>{' '}
                <span>Together.</span>
                {/* {' '}
                <span>Business.</span> */}
              </Typography>
            </Box>
          </Container>
        </Box>
        <Box display="flex" flexDirection="column" flexGrow={3}>
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {children}
          </Box>
          <Box textAlign="center" mt={2} p={2}>
            <Typography color="textSecondary" variant="caption">
              © 2022 Meyzer Group &#8226;{' '}
              <Link
                href="https://www.meyzer360.com/terms-conditions/"
                target="_blank"
                style={{
                  color: '#000000',
                }}
              >
                Terms & Conditions
              </Link>{' '}
              &#8226;{' '}
              <Link
                href="https://www.meyzer360.com/privacy-policy/"
                target="_blank"
                style={{
                  color: '#000000',
                }}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </main>
  );
};

export default Layout;
