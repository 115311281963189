import React from 'react';

import SignInLayout from 'src/components/SignIn/Layout';
import ResetPasswordForm from 'src/components/SignUp/ResetPasswordForm';

function ResetPassword(props) {
  return (
    <SignInLayout>
      <ResetPasswordForm />
    </SignInLayout>
  );
}

export default ResetPassword;
