import { useContext, useState, createContext } from 'react';
import { APIRequest } from 'src/utils/api';

const userContext = createContext({
  users: [],
  userError: '',
  userLoading: false,
  userSubmitting: false,
  Login: () => Promise.resolve({}),
  LoginAdmin: () => Promise.resolve({}),
  ListUserByCompanyRole: () => Promise.resolve({}),
  ListUserByCompanyMultipleRole: () => Promise.resolve({}),
  GetUserByID: () => Promise.resolve({}),
  GetUserContact: () => Promise.resolve({}),
  GetUserByEmail: () => Promise.resolve({}),
  ChangePassword: () => Promise.resolve({}),
  FirstTimeChangePassword: () => Promise.resolve({}),
});

export function useProvideUser() {
  const [user, setUser] = useState({});
  const [userError, setUserError] = useState();
  const [userLoading, setUserLoading] = useState(false);
  const [userSubmitting, setUserSubmitting] = useState(false);

  const Login = async (email, password) => {
    const res = await APIRequest({
      setError: setUserError,
      setLoading: setUserLoading,
      path: `${process.env.REACT_APP_API_URL}/usercontact/sysinfousers/login`,
      method: 'post',
      body: {
        username: email,
        password,
      },
    });
    console.log('@@@ res', res);
    if (res.status === 'success' && res?.body?.is_success) {
      setUser(res.body.user);
    }
    if (res.status === 'rejected' || !res?.body?.is_success) {
      setUserError(res.body.message);
    }
    return res;
  };

  const LoginAdmin = async (email, password) => {
    const res = await APIRequest({
      setError: setUserError,
      setLoading: setUserLoading,
      path: `${process.env.REACT_APP_API_URL}/usercontact/adminusers/login`,
      method: 'post',
      body: {
        username: email,
        password,
      },
    });
    console.log('@@@ res', res);
    if (res.status === 'success' && res?.body?.is_success) {
      setUser(res.body.user);
    }
    if (res.status === 'rejected' || !res?.body?.is_success) {
      setUserError(res.body.message);
    }
    return res;
  };

  const ResetPassword = async (email) => {
    const res = await APIRequest({
      setError: setUserError,
      setLoading: setUserLoading,
      path: `${process.env.REACT_APP_USERCONTACT_URL}/sysinfousers/reset-password-request`,
      method: 'put',
      body: {
        email: email,
      },
    });
    return res;
  };

  const GetUserByEmail = async (email) => {
    const res = await APIRequest({
      setError: setUserError,
      setLoading: setUserLoading,
      path: `${process.env.REACT_APP_USERCONTACT_URL}/sysinfousers/email/${email}`,
      method: 'get',
    });
    return res;
  };

  const ChangePassword = async (id, body) => {
    const res = await APIRequest({
      setError: setUserError,
      setLoading: setUserLoading,
      path: `${process.env.REACT_APP_USERCONTACT_URL}/sysinfousers/${id}`,
      method: 'put',
      body: body,
    });
    return res;
  };

  const FirstTimeChangePassword = async (id, body) => {
    const res = await APIRequest({
      setError: setUserError,
      setLoading: setUserLoading,
      path: `${process.env.REACT_APP_USERCONTACT_URL}/sysinfousers/password/${id}`,
      method: 'put',
      body: body,
    });
    return res;
  };

  return {
    user,
    userError,
    userLoading,
    userSubmitting,
    Login,
    LoginAdmin,
    ResetPassword,
    GetUserByEmail,
    ChangePassword,
    FirstTimeChangePassword,
  };
}

export const useUserContext = () => useContext(userContext);

export function ProvideUser({ children }) {
  const user = useProvideUser();
  return <userContext.Provider value={user}>{children}</userContext.Provider>;
}
