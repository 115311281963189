import React, { useState, useEffect } from 'react';
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Link,
  Grid,
  TextField,
  SvgIcon,
  Card,
  CardContent,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useSnackbar from 'src/hooks/useSnackbar';

import { ReactComponent as GoogleIcon } from 'src/assets/signin/google-icon.svg';
import ROUTES from 'src/constants/routes';
import { Cookies, useCookies } from 'react-cookie';
import FormCheckbox from 'src/components/Form/Checkbox';
import CustomPasswordField from '../CustomPasswordField';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { useProvideUser } from 'src/hooks/useUsers';
import { useProvideAffiliate } from 'src/hooks/useAffiliate';

import CountdownTimer from 'src/components/CountdownTimer';
import { useProvideOtp } from 'src/hooks/useOtp';
import OtpInput from 'react-otp-input';
import axios from 'axios';

function SignInForm(props) {
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { Login, LoginAdmin, userError, user } = useProvideUser();
  const { smBelow } = useBreakpoints();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [cookie, setCookie] = useCookies(['name']);
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState('');
  const [userValues, setUserValues] = useState({});
  const [submitValues, setSubmitValues] = useState({});
  const username = new Cookies().get('username');
  const companyID = new Cookies().get('company_id');
  const roleID = new Cookies().get('role_id');
  const refID = new Cookies().get('ref_id');
  const refid = new Cookies().get('refid');

  const { GetOtp, ValidateOtp } = useProvideOtp();
  const {
    affiliate,
    ReadAffiliateBySysInfoUserID,
    CreateAffiliate,
    UpdateAffiliate,
    loading: affiliateLoading,
    submitting: affiliateSubmitting,
    error: affiliateError,
  } = useProvideAffiliate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
    rememberMe: Yup.bool(),
  });

  const validationSchemaStep2 = Yup.object({});

  useEffect(() => {
    if (userError) {
      setLoading(false);
      showErrorSnackbar(`Failed to login. ${userError}`);
    }
  }, [userError]);

  useEffect(() => {
    if (step === 1) {
      requestOtp();
    }
  }, [step]);

  const formikOnSubmit = async (values, actions) => {
    setLoading(true);
    setMsg('');
    const clientID = searchParams.get('client_id');
    let res = null;
    if (clientID === 'meyzeradmin') {
      res = await LoginAdmin(values.email, values.password);
    } else {
      res = await Login(values.email, values.password);
    }

    values.user_id = res.body.users.user_id;
    if (res.body.users.is_2fa_enabled === 1) {
      setSubmitValues(values);
      setUserValues(res.body.users);
      setStep(1);
    } else if (
      res.body.users.last_login === null &&
      clientID !== 'meyzeradmin'
    ) {
      navigate(
        {
          pathname: ROUTES.FIRSTTIMECHANGEPASSWORDPAGE,
          search: `?${createSearchParams(
            Object.fromEntries([...searchParams]),
          )}`,
        },
        { state: values },
      );
    } else {
      redirectToDashboard(values);
    }

    setLoading(false);
  };

  const formikOnSubmitStep2 = async (values, actions) => {
    let res = await ValidateOtp(otp, {
      user_id: userValues.user_id,
    });
    if (res.status === 'success') {
      if (res.body.otp.is_verified === 1) {
        redirectToDashboard(submitValues);
      }
    } else {
      showErrorSnackbar(`Invalid OTP`);
    }
  };

  const requestOtp = async () => {
    let res = await GetOtp({
      mobile_number: userValues.contact_number,
      created_by: userValues.user_id,
    });
    console.log('OTP:', res.body.otp.otp);
    if (res.body.is_success === true) {
      setOtp(null);
    }
  };

  const redirectToDashboard = async (values) => {
    if (username && refID) {
      try {
        await axios.put(
          `${process.env.REACT_APP_USERCONTACT_URL}/sysinfouserroles/accept/username/${username}/company/${companyID}/role/${roleID}`,
          {
            ref_id: refID,
            sysinfo_user_id: values.user_id,
          },
        );
        await new Cookies().remove('username');
        await new Cookies().remove('company_id');
        await new Cookies().remove('role_id');
        await new Cookies().remove('ref_id');
      } catch (e) {}
    }

    // set referral
    if (refid) {
      const affRes = await ReadAffiliateBySysInfoUserID(values.user_id);
      if (affRes.status === 'success') {
        // affiliate found, update if referred is null
        if (!affRes.body.affiliate?.referred_by_affiliate_id) {
          try {
            const res = await UpdateAffiliate(
              affRes.body.affiliate.affiliate_id,
              {
                referred_by_affiliate_id: refid,
              },
            );
            console.log('@@@ res update', res);
            await new Cookies().remove('refid');
          } catch (e) {}
        }
      } else {
        // affiliate not found, create
        try {
          const res = await CreateAffiliate(values.user_id, refid);
          console.log('@@@ res create', res);
          await new Cookies().remove('refid');
        } catch (e) {}
      }
    }
    const redirectUri = searchParams.get('redirect_uri');
    if (redirectUri) {
      const clientID = searchParams.get('client_id');
      const qp_nonce = searchParams.get('nonce');
      const state = searchParams.get('state');

      const form = document.createElement('form');
      form.method = 'post';
      form.action = `${process.env.REACT_APP_AUTH_URL}/interaction/${state}/login`;

      const login = document.createElement('input');
      login.type = 'hidden';
      login.name = 'login';
      login.value = values.email;
      form.appendChild(login);

      const client_id = document.createElement('input');
      client_id.type = 'hidden';
      client_id.name = 'client_id';
      client_id.value = clientID;
      form.appendChild(client_id);

      const nonce = document.createElement('input');
      nonce.type = 'hidden';
      nonce.name = 'nonce';
      nonce.value = qp_nonce;
      form.appendChild(nonce);

      const password = document.createElement('input');
      password.type = 'hidden';
      password.name = 'password';
      password.value = values.password;
      form.appendChild(password);

      document.body.appendChild(form);

      form.submit();
      return;
    }
  };

  const stepOne = () => {
    return (
      <>
        <Box fontWeight="700" fontSize="25px" textAlign="center">
          Welcome to Your Digital Office
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Typography color="textSecondary" variant="body2">
            New to Meyzer360?&nbsp;
            <Link
              color="primary"
              onClick={() => {
                navigate({
                  pathname: ROUTES.SIGNUPPAGE,
                  search: `?${createSearchParams(
                    Object.fromEntries([...searchParams]),
                  )}`,
                });
              }}
              sx={{ cursor: 'pointer' }}
            >
              Register an account
            </Link>
          </Typography>
        </Box>
        <Formik
          initialValues={{
            email: username,
            password: '',
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form style={{ width: '100%' }}>
              <Box mb={2}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  style={{
                    display: 'flex',
                    marginBottom: '4px',
                  }}
                >
                  <span>Email address</span>
                </Typography>
                <Field name={'email'}>
                  {({ field, meta }) => (
                    <StyledTextField
                      name="email"
                      variant="outlined"
                      placeholder="you@example.com"
                      fullWidth
                      size="small"
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={
                        meta.touched && meta.error
                          ? meta.error
                          : props.helperText || ''
                      }
                    />
                  )}
                </Field>
              </Box>
              <Box mb={2}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '4px',
                  }}
                >
                  <span>Password</span>
                  <Link
                    href=""
                    onClick={() => {
                      navigate({
                        pathname: ROUTES.RESET_PASSWORD,
                        search: `?${createSearchParams(
                          Object.fromEntries([...searchParams]),
                        )}`,
                      });
                    }}
                    color="primary"
                  >
                    Forgot password?
                  </Link>
                </Typography>
                <CustomPasswordField name={'password'} hideStrength={true} />
                {/* <Field name={'password'}>
                {({ field, meta }) => (
                  <StyledTextField
                    type="password"
                    name="password"
                    variant="outlined"
                    placeholder="Enter 8 character or more"
                    fullWidth
                    size="small"
                    {...field}
                    error={meta.touched && !!meta.error}
                    helperText={
                      meta.touched && meta.error
                        ? meta.error
                        : props.helperText || ''
                    }
                  />
                )}
              </Field> */}
              </Box>
              {/* <Box mb={1}>
               <Field name="rememberMe">
                  {({ field, meta }) => (
                    <FormCheckbox
                      name="rememberMe"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      label={
                        // <Box display="flex" alignItems="center">
                        //   <Typography variant="body2">
                        //     Keep me logged in
                        //   </Typography>
                        // </Box>
                      }
                      {...field}
                    /> 
                  )}
                </Field>
              </Box> */}
              <LoadingButton
                variant="contained"
                color="primary"
                // disabled={!(props.isValid && props.dirty)}
                type="submit"
                // variant="contained"
                sx={{
                  color: '#FFFFFF',
                  textTransform: 'initial',
                  fontWeight: 700,
                }}
                size="large"
                fullWidth={true}
                disableElevation
                loading={loading || affiliateSubmitting || affiliateLoading}
              >
                Sign in
                {/* {loading ? <ProgressLoader loading={true} /> : 'Sign in'} */}
              </LoadingButton>
              {msg && <p>{msg}</p>}
            </Form>
          )}
        </Formik>
        {/* <Box display="flex" alignItems="center" justifyContent="center" p={3}>
          <Typography color="textSecondary" variant="body2">
            Or sign in with
          </Typography>
        </Box> */}
        {/* <Box> */}
        {/* <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              style={{
                backgroundColor: '#4267B2',
                color: '#ffffff',
              }}
              fullWidth={true}
              size="large"
              disableElevation
            >
              <FacebookIcon />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              style={{
                backgroundColor: '#F14336',
                color: '#fff',
              }}
              fullWidth={true}
              size="large"
              disableElevation
            >
              <SvgIcon style={{ color: '#fff' }}>
                <GoogleIcon />
              </SvgIcon>
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              style={{
                backgroundColor: '#000000',
                color: '#ffffff',
              }}
              fullWidth={true}
              size="large"
              disableElevation
            >
              <AppleIcon />
            </Button>
          </Grid>
        </Grid> */}
      </>
    );
  };

  const stepTwo = () => {
    return (
      <>
        <Box fontWeight="700" fontSize="30px" textAlign="center">
          2-step verification
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          // width={smBelow ? 'auto' : '275px'}
          width="100%"
        >
          <Box maxWidth="275px" width={'100%'}>
            <Typography color="textSecondary" variant="body2">
              {'Please enter the 6-digit verification code we sent to mobile number ' +
                userValues?.contact_number +
                '.'}
            </Typography>
          </Box>
        </Box>
        <Formik
          initialValues={{}}
          onSubmit={formikOnSubmitStep2}
          validationSchema={validationSchemaStep2}
        >
          {(props) => {
            return (
              <Form style={{ width: '100%' }}>
                <Box mb={2}>
                  <OtpInput
                    value={otp}
                    onChange={(newValue) => {
                      setOtp(newValue);
                    }}
                    numInputs={6}
                    isInputNum
                    shouldAutoFocus={true}
                    inputStyle={{
                      width: '3rem',
                      height: '3rem',
                      margin: '0 0.5rem',
                      borderRadius: 4,
                      border: '1px solid #E4E7F1',
                      fontWeight: 600,
                      fontSize: 22,
                    }}
                    focusStyle={{
                      border: '1px solid #A21D23',
                      outline: 'none',
                    }}
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  // disabled={!(props.isValid && props.dirty)}
                  type="submit"
                  // variant="contained"
                  style={{
                    color: '#FFFFFF',
                    textTransform: 'initial',
                    fontWeight: 700,
                  }}
                  size="large"
                  fullWidth={true}
                  disableElevation
                >
                  Verify
                </Button>
                {msg && <p>{msg}</p>}
                {/* <Box
                  display="flex"
                  justifyContent="center"
                  mt={loading ? 2 : 0}
                >
                  <ProgressLoader loading={loading} />
                </Box> */}
              </Form>
            );
          }}
        </Formik>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={3}
        >
          <CountdownTimer onReset={() => requestOtp()} />
          <Box>
            <Typography color="textSecondary" variant="body2">
              Need more help?{' '}
              <Link
                to="#"
                onClick={(e) => {
                  window.location = 'mailto:' + userValues.email;
                  e.preventDefault();
                }}
                color="primary"
              >
                Contact us
              </Link>
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: 16,
        border: smBelow ? 'none' : '',
        width: smBelow ? '100%' : '400px',
        maxWidth: '100%',
      }}
    >
      <CardContent>
        {step === 0 && stepOne()}
        {step === 1 && stepTwo()}
      </CardContent>
    </Card>
  );
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  root: {
    '& .MuiInputBase-root': {
      // '& fieldset': {
      borderRadius: `4px`,
      // },
      backgroundColor: '#fff',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}));

export default SignInForm;
