const ROUTES = {
  HOMEPAGE: '/',
  OTPPAGE: '/otp',

  SIGNUPPAGE: '/signup',
  SIGNINPAGE: '/signin',

  ACC_ACTIVATED: '/account-activated',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/reset-new-password',
  FIRSTTIMECHANGEPASSWORDPAGE: '/first-time-reset-new-password',

  PAGENOTFOUND: '/page-not-found',
};

export default ROUTES;
