import React from 'react';

import SignInLayout from 'src/components/SignIn/Layout';
import ChangePasswordForm from 'src/components/SignUp/ChangePasswordForm';

function ChangePassword(props) {
  return (
    <SignInLayout>
      <ChangePasswordForm />
    </SignInLayout>
  );
}

export default ChangePassword;
