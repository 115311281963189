const setItem = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getItem = (key) => {
  const dt = localStorage.getItem(key);

  if (!dt) return null;

  return JSON.parse(dt);
};

const deleteItem = (key) => {
  localStorage.removeItem(key);
};

const storageSvc = { setItem, getItem, deleteItem };

export default storageSvc;
