import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { Field } from 'formik';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     textAlign: 'left',
//   },
// }));

function FormCheckbox({ name, label, ...props }) {
  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <>
            <FormControl fullWidth error={meta.touched && !!meta.error}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    color="primary"
                    style={{ textAlign: 'left' }}
                    {...field}
                    {...props}
                  />
                }
                label={label}
                sx={{
                  textAlign: 'left',
                }}
              />
              {meta.touched && !!meta.error && (
                <FormHelperText>{meta.error}</FormHelperText>
              )}
            </FormControl>
          </>
        );
      }}
    </Field>
  );
}

export default FormCheckbox;
