let _store;

const setStore = (store) => {
  _store = store;
};

const dispatch = (event) => {
  _store.dispatch(event);
};

export const storeSvc = { setStore, dispatch };
