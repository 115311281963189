import React from 'react';
import { Field } from 'formik';
import { TextField, Typography, Box, Autocomplete } from '@mui/material';
import { Icon } from '@iconify/react';
import { Countries } from 'src/constants/list';

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        )
    : isoCode;
}

// const useStyles = makeStyles(() => ({
//   autocomplete: {
//     minWidth: 'unset',
//   },
// }));

const FormDropdownTextField = ({
  name,
  children,
  disabled,
  required,
  containerWidth = '100%',
  variant = 'standard',
  labelProps = {},
  shrink = false,
  showFlag = true,
  onChangeCode,
  onChangeNumber,
  ...props
}) => {
  // const classes = useStyles();

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <>
          <Box style={{ display: 'flex' }}>
            <Icon icon={props.icon} width="20" height="20" />

            <Typography
              color="textSecondary"
              variant="caption"
              style={{
                display: 'flex',
                marginBottom: '4px',
              }}
              {...labelProps}
            >
              {props.labeltext}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              style={{ paddingLeft: 5, fontStyle: 'italic', color: '#808080' }}
            >
              {props.optional && '(optional)'}
            </Typography>
          </Box>
          <div
            style={{
              display: 'flex',
              paddingBottom: 15,
              width: containerWidth,
            }}
          >
            {showFlag && (
              <div
                style={{
                  display: 'flex',
                  fontSize: 20,
                  paddingTop: 5,
                  paddingRight: 10,
                }}
              >
                {countryToFlag(props.countrycode)}
              </div>
            )}
            <Autocomplete
              variant={variant}
              options={Countries}
              disableClearable
              getOptionLabel={(option) => option.phone}
              defaultValue={{ code: 'SG', label: 'Singapore', phone: '+65' }}
              renderInput={(params) => (
                <TextField
                  color={props.color}
                  variant={variant}
                  {...params}
                  style={{ width: 130, paddingRight: 10 }}
                  sx={{
                    minWidth: 'unset',
                    '& .MuiInputBase-root': {
                      height: 40,
                      alignContent: 'center',
                    },
                  }}
                />
              )}
              onChange={onChangeCode}
            />
            <TextField
              {...field}
              {...props}
              disabled={disabled}
              InputLabelProps={shrink ? { shrink } : {}}
              variant={variant}
              error={meta.touched && !!meta.error}
              helperText={
                meta.touched && meta.error ? meta.error : props.helperText || ''
              }
              sx={{
                '& .MuiInputBase-root': { height: 40 },
              }}
              onChange={onChangeNumber}
            />
          </div>
        </>
      )}
    </Field>
  );
};

export default FormDropdownTextField;
