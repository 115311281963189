import React from 'react';

import SignInLayout from 'src/components/SignIn/Layout';
import SignInForm from 'src/components/SignIn/SignInForm';

function Signinpage(props) {
  return (
    <SignInLayout>
      <SignInForm />
    </SignInLayout>
  );
}

export default Signinpage;
