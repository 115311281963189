import axios from './customAxios';

const countries = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_COMMON_URL}/country`,
  );

  // if (data && !data?.is_success) {
  //   console.log('i am here');
  //   throw Error(data?.message);
  // }

  // console.log(data);

  return data;
};

const genders = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_COMMON_URL}/gender`,
  );

  // if (data && !data?.is_success) {
  //   console.log('i am here');
  //   throw Error(data?.message);
  // }

  // console.log(data);

  return data;
};

const companyApplicationTypes = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_COMMON_URL}/companyapplicationtype`,
  );

  return data;
};

const companyTypes = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_COMMON_URL}/companytype`,
  );

  return data;
};

const phoneCodes = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_COMMON_URL}/phonecode`,
  );

  return data;
};
const commonSvc = {
  countries,
  genders,
  companyApplicationTypes,
  companyTypes,
  phoneCodes,
};

export default commonSvc;
