import React from 'react';
import {
  Card,
  CardContent,
  Box,
  Typography,
  Avatar,
  Button,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import SignInLayout from 'src/components/SignIn/Layout';
import useBreakpoints from 'src/hooks/useBreakpoints';
import ROUTES from 'src/constants/routes';

function AccountActivated(props) {
  const { smBelow } = useBreakpoints();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const name = 'someone';

  return (
    <SignInLayout>
      <Card
        variant="outlined"
        style={{
          borderRadius: 16,
          border: smBelow ? 'none' : '',
          width: smBelow ? '100%' : '400px',
          maxWidth: '100%',
        }}
      >
        <CardContent>
          <Box display="flex" justifyContent="center" mt={4}>
            <Avatar
              sx={{
                backgroundColor: '#A21D23',
                width: '56px',
                height: '56px',
              }}
            >
              <CheckIcon />
            </Avatar>
          </Box>
          <Box fontWeight="700" fontSize="30px" textAlign="center" mt={3}>
            Account activated
          </Box>
          <Box
            mb={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            // width={smBelow ? 'auto' : '275px'}
            width="100%"
            mt={3}
          >
            <Box maxWidth="300px" width={'100%'}>
              <Typography color="textSecondary" variant="body2">
                {`Thank you ${name}, your mobile number has been verified. Your account is now activated. Please use button below to sign in your account`}
              </Typography>
            </Box>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate({
                    pathname: ROUTES.SIGNINPAGE,
                    search: `?${createSearchParams(
                      Object.fromEntries([...searchParams]),
                    )}`,
                  });
                }}
                style={{
                  color: '#FFFFFF',
                  textTransform: 'initial',
                  fontWeight: 700,
                }}
                size="large"
                fullWidth={true}
                disableElevation
              >
                Sign in to your account
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </SignInLayout>
  );
}

export default AccountActivated;
