import React, { useState, useEffect, useRef } from 'react';
import { Typography, ButtonBase } from '@mui/material';

const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
};

const INITIAL_COUNT = 600;

const twoDigits = (num) => String(num).padStart(2, '0');

const CountdownTimer = ({ onReset }) => {
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const [status, setStatus] = useState(STATUS.STOPPED);

  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const timeout = secondsToDisplay == 0 && minutesToDisplay == 0;

  useEffect(() => {
    handleStart();
  }, []);

  const handleStart = () => {
    setStatus(STATUS.STARTED);
  };
  const handleStop = () => {
    setStatus(STATUS.STOPPED);
  };
  const handleReset = () => {
    setStatus(STATUS.STOPPED);
    setSecondsRemaining(INITIAL_COUNT);
    handleStart();
    onReset();
  };

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
  );
  return (
    <ButtonBase
      disableRipple
      style={{
        cursor: timeout ? 'pointer' : 'auto',
      }}
      onClick={() => {
        if (timeout) handleReset();
      }}
    >
      <Typography variant="body1" display="inline" style={{ color: '#888B94' }}>
        Didn’t receive a code? Resend&nbsp;
        <Typography
          variant="body1"
          display="inline"
          style={{ color: '#000000', fontWeight: 600 }}
        >
          {timeout ? (
            <>Now</>
          ) : (
            <>
              ({twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}s)
            </>
          )}
        </Typography>
      </Typography>
    </ButtonBase>
  );
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // set up the interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default CountdownTimer;
