import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Link,
  Grid,
  TextField,
  SvgIcon,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
// import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Form, Formik, Field, useField } from 'formik';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const CustomPasswordField = ({ label, hideStrength = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordToolTip, setShowPasswordToolTip] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const { value } = field;

  useEffect(() => {
    // check password here
    const allConditionPattern = /^((?=.*[a-z])((?=.*\d)|(?=.*[!@#$%&*?]))).{8,}$/;
    if (!hideStrength) {
      const conditions = [
        {
          label: 'At least 8 characters',
          passed: false,
          pattern: /^((?=.*[a-z])|(?=.*[A-Z])|(?=.*\d)|(?=.*[!@#$%&*?])).{8,}$/,
          // custom: (value) => {
          //   return value.replace(/\s/g, '').length >= 8;
          // },
        },
        {
          label: 'At least one small letter',
          passed: false,
          pattern: /(?=.*[a-z])/,
        },
        {
          label: 'At least one number or symbol',
          passed: false,
          pattern: /(?=.*\d)|(?=.*[!@#$%&*?])/,
        },
      ];

      if (value) {
        let strongPassword = new RegExp(
          /((?=.*[a-z])((?=.*\d)|(?=.*[!@#$%&*?]))).{8,}/,
        );
        let mediumPassword = new RegExp(
          /((((?=.*[a-z])|(?=.*[A-Z])|(?=.*\d)|(?=.*[!@#$%&*?])).{8,})(?=.*[a-z]))|(((?=.*[a-z])|(?=.*[A-Z])|(?=.*\d)|(?=.*[!@#$%&*?])).{8,})(?=.*\d)|(?=.*[!@#$%&*?])|((?=.*[a-z])(?=.*\d)|(?=.*[!@#$%&*?]))/,
        );
        // let strongPassword = new RegExp(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})$/,
        // );
        // let mediumPassword = new RegExp(
        //   /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))$/,
        // );
        if (strongPassword.test(value, 'g')) {
          setPasswordStrength(3);
        } else {
          if (mediumPassword.test(value, 'g')) {
            setPasswordStrength(2);
          } else {
            if (value) {
              setPasswordStrength(1);
            }
          }
        }

        let newValue = conditions.map((aCondition) => {
          let isPassed = false;
          if (aCondition.custom) {
            isPassed = aCondition.custom(value);
          } else {
            if (aCondition.pattern) {
              let regex = new RegExp(aCondition.pattern);
              isPassed = regex.test(value, 'g');
            }
          }
          return {
            ...aCondition,
            passed: isPassed,
          };
        });
        handlePasswordTooltipOpen(newValue);
      } else {
        setPasswordStrength(0);
        handlePasswordTooltipOpen(false);
      }
    }
  }, [value]);

  const ConditionsStatus = ({ value }) => {
    return (
      <Box p={1}>
        <Box mb={1}>Password must include</Box>
        {value &&
          value.map((anItem, index) => {
            const { passed, label } = anItem;
            return (
              <Box key={index} display="flex" alignItems="center">
                <Box>
                  {passed ? (
                    <CheckIcon
                      size="small"
                      style={{
                        fill: theme.palette.success.main,
                      }}
                    />
                  ) : (
                    <CloseIcon size="small" color="error" />
                  )}
                </Box>
                <Box flexGrow={1} display="flex" alignItems="center" ml={1}>
                  {label}
                </Box>
              </Box>
            );
          })}
      </Box>
    );
  };

  const PasswordStrength = ({ level = 0 }) => {
    const strengthLevels = {
      0: {
        label: '',
      },
      1: {
        label: 'Weak',
      },
      2: {
        label: 'Medium',
      },
      3: {
        label: 'Strong',
      },
    };

    let levelDisplay = [];
    for (let i = 0; i < level; i++) {
      levelDisplay.unshift(
        <Box
          key={i}
          borderBottom={`2px solid ${theme.palette.primary.main}`}
          width="35px"
          marginLeft={'5px'}
        />,
      );
    }
    return (
      <Box>
        <Box textAlign="right">{strengthLevels[level].label}</Box>
        <Box display="flex">{levelDisplay}</Box>
      </Box>
    );
  };

  const handlePasswordTooltipOpen = (value) => {
    setShowPasswordToolTip(value);
  };
  const handlePasswordTooltipClose = () => {
    setShowPasswordToolTip(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };

  let passwordField = (
    <StyledTextField
      type={showPassword ? 'text' : 'password'}
      // name={name}
      variant="outlined"
      placeholder="Enter 8 character or more"
      fullWidth
      size="small"
      {...field}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : ''}
      InputProps={{
        // <-- This is where the toggle button is added.
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
  return (
    <Box position="relative">
      {label}
      {!hideStrength && (
        <Typography
          color="textSecondary"
          variant="caption"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '4px',
          }}
        >
          <span>Password</span>
          {!hideStrength && (
            <div>
              <PasswordStrength level={passwordStrength} />
            </div>
          )}
        </Typography>
      )}
      {hideStrength ? (
        passwordField
      ) : (
        <StyledTooltip
          open={Boolean(showPasswordToolTip)}
          onClose={handlePasswordTooltipClose}
          // onOpen={handlePasswordTooltipOpen}
          title={<ConditionsStatus value={showPasswordToolTip} />}
          placement="right"
          arrow={true}
        >
          {passwordField}
        </StyledTooltip>
      )}
    </Box>
  );
};

export default CustomPasswordField;

const StyledTextField = styled(TextField)(({ theme }) => ({
  root: {
    '& .MuiInputBase-root': {
      // '& fieldset': {
      borderRadius: theme.shape.borderRadius,
      // },
      backgroundColor: '#fff',
      paddingRight: 0,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    // maxWidth: 220,
    // fontSize: theme.typography.pxToRem(12),
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: theme.palette.common.white,
  },
}));
