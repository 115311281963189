import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
  useLocation,
} from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';

import Signuppage from '../pages/signup';
import Signinpage from '../pages/signin';
import ResetPasswordpage from '../pages/resetpassword';
import ChangePasswordpage from '../pages/changepassword';
import FirstTimeChangePasswordpage from '../pages/firsttimechangepassword';
import AccountActivated from 'src/pages/accountactivated';
import { nanoid } from 'nanoid';

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [usernameCookie, setUsernameCookie] = useCookies(['username']);
  const [companyCookie, setCompanyCookie] = useCookies(['company_id']);
  const [roleCookie, setRoleCookie] = useCookies(['role_id']);
  const [refCookie, setRefCookie] = useCookies(['ref_id']);
  // const isRegister = new Cookies().get('isRegister');
  // const emailCookie = new Cookies().get('email');
  // const passwordCookie = new Cookies().get('password');

  useEffect(() => {
    const redirectUri = searchParams.get('redirect_uri');
    const nonce = searchParams.get('nonce');
    const clientID = searchParams.get('client_id');
    const responseType = searchParams.get('response_type');
    const scope = searchParams.get('scope');
    const state = searchParams.get('state');
    const refID = searchParams.get('ref_id');
    const refid = searchParams.get('refid'); // referral id
    const username = searchParams.get('username');
    const companyID = searchParams.get('company_id');
    const roleID = searchParams.get('role_id');

    // if (isRegister && state) {
    //   new Cookies().remove('isRegister');
    //   new Cookies().remove('email');
    //   new Cookies().remove('password');
    //   const form = document.createElement('form');
    //   form.method = 'post';
    //   form.action = `${process.env.REACT_APP_AUTH_URL}/interaction/${state}/login`;

    //   const login = document.createElement('input');
    //   login.type = 'hidden';
    //   login.name = 'login';
    //   login.value = emailCookie;
    //   form.appendChild(login);

    //   const client_id = document.createElement('input');
    //   client_id.type = 'hidden';
    //   client_id.name = 'client_id';
    //   client_id.value = clientID;
    //   form.appendChild(client_id);

    //   const password = document.createElement('input');
    //   password.type = 'hidden';
    //   password.name = 'password';
    //   password.value = passwordCookie;
    //   form.appendChild(password);

    //   document.body.appendChild(form);

    //   form.submit();
    //   return;
    // }

    if (refid) {
      setRefCookie('refid', refid);
    }

    if (username) {
      setUsernameCookie('username', username);
      setCompanyCookie('company_id', companyID);
      setRoleCookie('role_id', roleID);
      setRefCookie('ref_id', refID);
    }

    let baseURL = `${process.env.REACT_APP_AUTH_URL}/auth?`;
    let oriRedirectURI = window.location.href.split('?')[0];
    if (!redirectUri) {
      baseURL += `&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
    }
    if (!nonce) {
      baseURL += `&nonce=${nanoid()}`;
    }
    if (!clientID) {
      baseURL += `&client_id=${process.env.REACT_APP_CLIENT_ID}`;
    }
    if (!responseType) {
      baseURL += `&response_type=code&code_challenge=${process.env.REACT_APP_CODE_CHALLENGE}&code_challenge_method=S256`;
    }
    if (!scope) {
      baseURL += `&scope=openid%20profile%20email`;
    }
    if (location.pathname === ROUTES.HOMEPAGE) {
      oriRedirectURI = `${window.location.href.split('?')[0]}signin`;
    }
    baseURL += `&oriRedirectURI=${oriRedirectURI}`;
    // for email redirection for Reset Password
    // if (refID && !username) {
    //   navigate({
    //     pathname: ROUTES.CHANGE_PASSWORD,
    //     search: `?${createSearchParams(Object.fromEntries([...searchParams]))}`,
    //   });
    // }
    // comment this to disable redirection for localhost
    if (!state) {
      // get new state everytime user attemp to signin
      window.location.href =
        baseURL +
        `&${createSearchParams(Object.fromEntries([...searchParams]))}`;
      return;
    }

    setLoading(false);
  }, []);

  return (
    <Suspense fallback={<h1>loading</h1>}>
      {!loading && (
        <Routes>
          <Route path={ROUTES.SIGNINPAGE} element={<Signinpage />} />
          <Route
            path={ROUTES.HOMEPAGE}
            element={<Navigate to={ROUTES.SIGNINPAGE} />}
          />
          <Route path={ROUTES.SIGNUPPAGE} element={<Signuppage />} />
          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordpage />} />
          <Route
            path={ROUTES.CHANGE_PASSWORD}
            element={<ChangePasswordpage />}
          />
          <Route
            path={ROUTES.FIRSTTIMECHANGEPASSWORDPAGE}
            element={<FirstTimeChangePasswordpage />}
          />
          <Route path={ROUTES.ACC_ACTIVATED} element={<AccountActivated />} />
        </Routes>
      )}
    </Suspense>
  );
};

export default AppRoutes;
