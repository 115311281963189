import { combineReducers } from 'redux';
import { storeSvc } from 'src/services/store';

const initState = {
  auth: { timespan: 0, isAuthenticated: false, data: {} },
  todos: [],
};

const ACTIONS = {
  IS_AUTHENTICATED: 'IS_AUTHENTICATED',
  TODOS: 'TODOS',
};

export const setIsAuth = (payload) => {
  storeSvc.dispatch({ type: ACTIONS.IS_AUTHENTICATED, payload });
};

export const setTodosState = (payload) => {
  storeSvc.dispatch({ type: ACTIONS.TODOS, payload });
};

const globalReducer = (state = initState, action) => {
  switch (action.type) {
    case ACTIONS.IS_AUTHENTICATED:
      return {
        ...state,
        auth: { ...action.payload, timestamp: Date.now() },
      };
    case ACTIONS.TODOS:
      return {
        ...state,
        todos: action.payload,
      };
    default:
      return { ...state };
  }
};

const rootReducer = combineReducers({
  global: globalReducer,
});

export default rootReducer;
