import React from 'react';
import { Typography, FormHelperText } from '@mui/material';

const FormFieldContainer = (props) => {
  const {
    label,
    helperText,
    error,
    labelProps = {},
    children,
    ...restProps
  } = props;

  return (
    <>
      {label && (
        <Typography
          color="textSecondary"
          variant="caption"
          // variant="body2"
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            // alignItems: 'flex-end',
            marginBottom: '4px',
          }}
          {...labelProps}
        >
          {label}
        </Typography>
      )}
      {children && (typeof children == 'function' ? children(props) : children)}
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </>
  );
};

export default FormFieldContainer;
