import React from 'react';
import { TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Field } from 'formik';

import FormFieldContainer from '../FormFieldContainer';

const StyledTextField = withStyles(TextField, (theme) => ({
  root: {
    '& .MuiInputBase-root': {
      // '& fieldset': {
      borderRadius: `4px`,
      // },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}));

const CustomTextField = (props) => {
  const {
    label,
    name,
    containerProps: defaultContainerProps = {},
    helperText,
    children,
    ...restProps
  } = props;

  let fieldProps = {
    name: name,
    variant: 'outlined',
    fullWidth: true,
    size: 'small',
    ...restProps,
  };

  return (
    <Field name={name}>
      {({ field, meta }) => {
        let error = meta.touched && !!meta.error;
        return (
          <FormFieldContainer
            label={label}
            error={error}
            helperText={meta.touched && meta.error ? meta.error : ''}
            {...defaultContainerProps}
          >
            <StyledTextField
              {...field}
              error={error}
              {...fieldProps}
              sx={{ backgroundColor: props.disabled ? '#E4E7F1' : '#fff' }}
            />
            {children}
          </FormFieldContainer>
        );
      }}
    </Field>
  );
};

export default CustomTextField;
