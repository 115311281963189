import React, { useState, useContext, useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import { Box, Button, TextField, Card, CardContent } from '@mui/material';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import ProgressLoader from '../../Progress';
import { ConfigContext } from 'src/contexts';
import CustomPasswordField from 'src/components/CustomFormFields/CustomPasswordField';

import useBreakpoints from 'src/hooks/useBreakpoints';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import { useMergedClasses } from 'tss-react';
import CustomTextField from '../../CustomFormFields/CustomTextField';
import { useProvideUser } from 'src/hooks/useUsers';
import useSnackbar from 'src/hooks/useSnackbar';
import ROUTES from '../../../constants/routes';

const useStyles = makeStyles()((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  radioWrapper: {
    borderColor: theme.palette.grey['400'],
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '44px',
    color: theme.palette.grey['600'],
  },
  selectedRadioWrapper: {
    backgroundColor: 'rgba(255, 228, 142, 0.19)',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    marginLeft: 0,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: '44px',
  },
}));

const fieldName_email = 'email';
const fieldName_new_password = 'new_password';
const fieldName_confirm_password = 'confirm_password';

function ChangePasswordForm(props) {
  const navigate = useNavigate();
  const location = useLocation();
  let { classes } = useStyles();
  classes = useMergedClasses(classes, props.classes);
  const theme = useTheme();
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { smBelow } = useBreakpoints();
  const [email, setEmail] = useState('');
  const { GetUserByEmail, ChangePassword } = useProvideUser();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setEmail(params.email);
  }, [location.search]);

  const validationSchema = Yup.object({
    // [fieldName_email]: Yup.string()
    //   .email('Invalid email format')
    //   .required('Email is required'),
    [fieldName_new_password]: Yup.string().required('New password is required'),
    [fieldName_confirm_password]: Yup.string()
      .required('Confirm password is required')
      .oneOf(
        [Yup.ref(fieldName_new_password), null],
        'Confirm Password must match with New Password',
      ),
  });

  const formikOnSubmit = async (values, actions) => {
    const emailAddress = !!email ? email : values.email;
    let res = await GetUserByEmail(emailAddress);
    if (res.status === 'success' && res.body.is_success === true) {
      res = await ChangePassword(res.body.users.user_id, {
        password: values.new_password,
        email: emailAddress,
      });
      if (res.status === 'success' && res.body.is_success === true) {
        showSuccessSnackbar(`Password updated.`);
        window.location.href = `${process.env.REACT_APP_ACCESS}`;
        // navigate({
        //   pathname: ROUTES.SIGNINPAGE,
        //   search: `?${createSearchParams(
        //     Object.fromEntries([...searchParams]),
        //   )}`,
        // });
      } else {
        showErrorSnackbar(
          `Opps, something went wrong, please try again later.`,
        );
      }
    } else {
      showErrorSnackbar(`User does not exist.`);
    }
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: 16,
        border: smBelow ? 'none' : '',
        width: smBelow ? '100%' : '400px',
        maxWidth: '100%',
      }}
    >
      <CardContent>
        <Box py={2} fontWeight="700" fontSize="30px" textAlign="center">
          Reset password
        </Box>
        <Formik
          initialValues={{
            [fieldName_email]: email,
            [fieldName_new_password]: '',
            [fieldName_confirm_password]: '',
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => {
            return (
              <Form style={{ width: '100%' }}>
                <Box mb={2}>
                  <CustomTextField
                    label="Email address"
                    name={fieldName_email}
                    placeholder="you@example.com"
                    value={email}
                    disabled={!!email}
                  />
                </Box>
                <Box mb={2}>
                  <CustomPasswordField
                    label="New password"
                    name={fieldName_new_password}
                    showStrength={true}
                  />
                </Box>
                <Box mb={2}>
                  <CustomPasswordField
                    label="Confirm password"
                    name={fieldName_confirm_password}
                    showStrength={true}
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{
                    color: '#FFFFFF',
                    textTransform: 'initial',
                    fontWeight: 700,
                  }}
                  size="large"
                  fullWidth={true}
                  disableElevation
                >
                  Update password
                </Button>
                {msg && <p>{msg}</p>}
                <Box
                  display="flex"
                  justifyContent="center"
                  mt={loading ? 2 : 0}
                >
                  <ProgressLoader loading={loading} />
                </Box>
              </Form>
            );
          }}
        </Formik>
        <Box p={4} />
      </CardContent>
    </Card>
  );
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  root: {
    '& .MuiInputBase-root': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: '#fff',
      paddingRight: 0,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}));

ChangePasswordForm.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ChangePasswordForm;
