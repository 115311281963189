import React from 'react';

import SignInLayout from 'src/components/SignIn/Layout';
import SignUpForm from 'src/components/SignUp/SignUpForm';

function Signuppage(props) {
  return (
    <SignInLayout>
      <SignUpForm />
    </SignInLayout>
  );
}

export default Signuppage;
