import React from 'react';

import SignInLayout from 'src/components/SignIn/Layout';
import FirstTimeChangePasswordForm from 'src/components/SignUp/FirstTimeChangePasswordForm';

function FirstTimeChangePassword(props) {
  return (
    <SignInLayout>
      <FirstTimeChangePasswordForm />
    </SignInLayout>
  );
}

export default FirstTimeChangePassword;
